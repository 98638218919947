// import React, { useState } from "react";
// import PhoneInput from "react-phone-number-input";
// import "react-phone-number-input/style.css";

// function Phone() {
//   const [value, setValue] = useState(null); // Start with null for uninitialized phone number

//   return (
//     <div>
//       <PhoneInput
//         placeholder="Enter phone number"
//         value={value}
//         onChange={setValue}
//         defaultCountry="IN" // Set a default country for better UX
//       />
//     </div>
//   );
// }

// export default Phone;






import React, { useState } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

function Phone() {
  // Define state variables for the form fields
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState(null); // Using null as initial value for the phone
  const [address, setAddress] = useState("");

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Simple validation check
    if (!name || !email || !phone || !address) {
      alert("Please fill in all fields");
      return;
    }

    // If all fields are filled, log the form data
    const formData = { name, email, phone, address };
    console.log("Form submitted successfully:", formData);

    // You can add further logic here like sending the data to an API
  };

  return (
    <div className="container">
      <h2>Basic Details Form</h2>
      <form onSubmit={handleSubmit} className="form">
        {/* Name Field */}
        <div className="form-group">
          <label>Name</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter your name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>

        {/* Email Field */}
        <div className="form-group">
          <label>Email</label>
          <input
            type="email"
            className="form-control"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>

        {/* Phone Field */}
        <div className="form-group">
          <label>Phone Number</label>
          <PhoneInput
            placeholder="Enter phone number"
            value={phone}
            onChange={setPhone}
            defaultCountry="IN"
            required
          />
        </div>

        {/* Address Field */}
        <div className="form-group">
          <label>Address</label>
          <textarea
            className="form-control"
            placeholder="Enter your address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            required
          />
        </div>

        {/* Submit Button */}
        <div className="form-group">
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}

export default Phone;
